




import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';

const startRouteGuard = (to, from, next) => {
  store.dispatch('api/getUserFromLocalStorage');

  if (store.getters['api/isLoggedIn']) {
    if (to.path === '/login' || to.path === '/') {
      next('/main');
    } else {
      next();
    }
  } else {
    if (to.path === '/' || (to.path as string).startsWith('/main')) {
      next('/login');
    } else {
      next();
    }
  }
}

@Component
export default class Start extends Vue {

  public beforeRouteEnter(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    startRouteGuard(to, from, next);
  }
}
